import { breakpoints, getRgbaColor, shadows, space, v2Colors } from '@web-for-marketing/react-ui';

export const navbarStyles = {
    stickyNavbar: {
        position: 'sticky',
        top: 0,
        boxShadow: shadows.soft,
        backgroundColor: v2Colors.background.white,
        zIndex: 400,
        [`@media (max-width: ${breakpoints.md})`]: {
            position: 'static',
        },
    },
    header: {
        boxShadow: 'none',
        position: 'relative',
        backgroundColor: v2Colors.background.white,
    },
    preview: {
        pointerEvents: 'none',
        borderRadius: '.8rem .8rem 0 0',
        width: '100%',
        overflow: 'hidden',
    },
    microNav: (shrink?: boolean, preview?: boolean) =>
        ({
            backgroundColor: v2Colors.background.dark,
            padding: shrink ? '0 1rem' : '0.5rem 3.6rem',
            minHeight: '4rem !important',
            justifyContent: 'space-between',
            borderRadius: preview ? '0.8rem 0.8rem 0 0' : undefined,
        }) as const,
    mainNav: (shrink?: boolean) =>
        ({
            backgroundColor: v2Colors.background.white,
            padding: shrink ? '0 1rem' : '0 3.6rem',
            color: v2Colors.text.tertiary,
            minHeight: shrink ? '6rem !important' : '10rem !important',
            justifyContent: 'space-between',
            [`@media (max-width: ${breakpoints.md})`]: {
                paddingLeft: space.containerOuterPadding,
                paddingRight: space.containerOuterPadding,
            },
        }) as const,
    microContent: (shrink?: boolean) =>
        ({
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:last-child)': {
                marginRight: shrink ? '1rem' : '4rem',
                '@media (max-width: 1159.9px)': {
                    marginRight: shrink ? '1rem' : '2rem',
                },
            },
        }) as const,
    microRight: {
        marginLeft: '2rem',
    },
    logodDivider: {
        borderLeft: `1px solid ${v2Colors.border.disabled}`,
        height: '2.4rem',
        margin: '0 2rem',
    },
    searchDivider: {
        borderLeft: `1px solid ${v2Colors.border.primary}`,
        height: '2.4rem',
        marginRight: '2rem',
    },
    languageRegionDivider: (shrink?: boolean) =>
        ({
            marginRight: shrink ? '1rem' : '2rem',
            borderLeft: `1px solid ${v2Colors.border.inversePrimary}`,
            height: '2.1rem',
        }) as const,
    mainContentLeft: {
        display: 'flex',
        alignSelf: 'stretch',
        alignItems: 'center',
    },
    logosContainer: {
        display: 'flex',
        alignSelf: 'stretch',
        alignItems: 'center',
    },
    mainRightContent: (shrink?: boolean) =>
        ({
            display: 'flex',
            '& > *:not(:last-child)': {
                marginRight: shrink ? '1rem' : '2rem',
            },
        }) as const,
    mainLeftContent: (hasSecondaryLogo: boolean, shrink?: boolean) =>
        ({
            display: 'flex',
            alignSelf: 'stretch',
            alignItems: 'stretch',
            marginLeft: hasSecondaryLogo || shrink ? '4rem' : '8rem',
            marginRight: '1rem',
            '@media (max-width: 1159.9px)': {
                marginLeft: hasSecondaryLogo ? '2rem' : '4rem',
            },
            '& > *:not(:last-child)': {
                marginRight: shrink ? '1rem' : '4rem',
                '@media (max-width: 1159.9px)': {
                    marginRight: shrink ? '1rem' : '2rem',
                },
            },
        }) as const,
    logo: (hasSecondaryLogo: boolean, shrink?: boolean) =>
        ({
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: shrink ? '8rem' : hasSecondaryLogo ? '15rem' : '18rem',
            '@media (max-width: 1159.9px)': {
                width: shrink ? '8rem' : '12rem',
            },
        }) as const,
    logoImage: {
        maxWidth: '100%',
        height: '2.4rem',
        objectFit: 'contain',
    },
    loginButton: (shrink?: boolean) =>
        ({
            textDecoration: 'none',
            color: v2Colors.text.inversePrimary,
            border: `1px solid ${v2Colors.text.inversePrimary}`,
            padding: '0.5rem 1.6rem',
            fontSize: shrink ? '1rem' : '1.4rem',
            borderRadius: '6px',
            transition: 'background-color ease 500ms',
            '&:hover': {
                backgroundColor: v2Colors.text.activePrimary,
            },
            '&:focus': {
                backgroundColor: v2Colors.text.activePrimary,
                outline: 'none',
            },
        }) as const,
    microRightLink: (shrink?: boolean) =>
        ({
            textDecoration: 'none',
            color: v2Colors.text.inversePrimary,
            fontSize: shrink ? '1rem' : '1.4rem',
            whiteSpace: 'nowrap',
            '&:hover': {
                textDecoration: 'underline',
            },
            '&:focus': {
                textDecoration: 'underline',
                outline: 'none',
            },
            '& svg': {
                height: shrink ? '0.8rem' : '1.2rem',
                width: shrink ? '0.8rem' : '1.2rem',
            },
        }) as const,
    microLeftLink: (shrink?: boolean) =>
        ({
            color: v2Colors.text.inversePrimary,
            textDecoration: 'underline',
            fontSize: shrink ? '1rem' : '1.4rem',
            padding: '0.4rem',
            borderRadius: '3px',
            transition: 'background-color ease 500ms',
            minWidth: '30rem',
            '&:hover': {
                backgroundColor: getRgbaColor(v2Colors.background.darkest, 0.3),
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: getRgbaColor(v2Colors.background.darkest, 0.3),
            },
            '& svg': {
                height: shrink ? '0.8rem' : '1.2rem',
                width: shrink ? '0.8rem' : '1.2rem',
            },
        }) as const,
    languageButton: (shrink?: boolean) =>
        ({
            textDecoration: 'none',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            fontSize: shrink ? '1rem' : '1.4rem',
            color: v2Colors.text.inversePrimary,
            display: 'flex',
            alignItems: 'center',
            padding: '0.6rem 0.8rem',
            borderRadius: '6px',
            transition: 'background-color ease 500ms',
            whiteSpace: 'nowrap',
            '& svg': {
                height: shrink ? '1rem' : '1.4rem',
                width: shrink ? '1rem' : '1.4rem',
            },
            '& img': {
                height: shrink ? '1.5rem' : undefined,
                width: shrink ? '1.5rem' : undefined,
                top: 0,
            },
            '& svg path': {
                stroke: v2Colors.text.inversePrimary,
            },
            '&:hover': {
                backgroundColor: getRgbaColor(v2Colors.background.darkest, 0.3),
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: getRgbaColor(v2Colors.background.darkest, 0.3),
            },
        }) as const,
    mainRightButton: (shrink?: boolean) =>
        ({
            fontSize: shrink ? '1.2rem !important' : '1.6rem !important',
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            padding: shrink ? '0.5rem 1rem !important' : '1rem 2rem !important',
            minHeight: 0,
            '& svg': {
                height: shrink ? '1rem' : '1.4rem',
                width: shrink ? '1rem' : '1.4rem',
                marginBottom: '0.2rem',
            },
            textAlign: 'center',
        }) as const,
    openDropdownButton: {
        '&::after': {
            height: 2,
        },
    },
    underlineMenuItem: {
        all: 'unset',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        '&:hover, &:focus-visible': {
            '&::after': {
                height: 2,
            },
        },
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: 0,
            width: '100%',
            transition: 'height 300ms ease',
            backgroundColor: v2Colors.core.innovation,
            transform: 'translateY(1px)',
            zIndex: 100,
        },
    },
    smallMainText: {
        fontSize: '1.2rem !important',
        '& svg': {
            height: '1rem',
            width: '1rem',
        },
    },
    mainLeftItem: {
        '&:hover, &:focus-visible': {
            '& > *': {
                color: v2Colors.text.activeSecondary,
            },
        },
    },
    dropdownButton: {
        height: '100%',
    },
    dropdown: {
        backgroundColor: v2Colors.background.white,
        boxShadow: shadows.soft,
        width: '100%',
        zIndex: -1,
        position: 'absolute',
        left: 0,
        transform: 'translateY(-100%)',
        visibility: 'hidden',
        overflow: 'hidden',
        transition: 'transform 350ms cubic-bezier(0, 0, 0.2, 1), visibility 350ms cubic-bezier(0, 0, 0.2, 1)',
    },
    showOpen: {
        transform: 'translateY(0px)',
        visibility: 'visible',
        overflow: 'visible',
        transition: 'transform 350ms cubic-bezier(0, 0, 0.2, 1), visibility 350ms cubic-bezier(0, 0, 0.2, 1)',
        '&:before': {
            transform: 'scaleY(1)',
        },
    },
    columns: (preview?: boolean) =>
        ({
            padding: '6rem 4rem 0 4rem',
            maxHeight: preview ? undefined : 'calc(100vh - 30rem)',
            overflowY: 'auto',
        }) as const,
    columnsContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '6rem',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '40rem',
        '&:not(:last-of-type)': {
            marginRight: '8rem',
            '@media (max-width: 1159.9px)': {
                marginRight: '4rem',
            },
        },
        '& > *:not(:last-child)': {
            marginBottom: '0.4rem',
        },
    },
    columnLink: {
        border: `1px solid ${v2Colors.background.white}`,
        backgroundColor: v2Colors.background.light,
        borderRadius: '8px',
        padding: '0.8rem 1.6rem',
        width: 'fit-content',
        fontSize: '1.6rem',
        color: v2Colors.text.activeSecondary,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        transition: 'border-color ease 500ms',
        '&:hover,:focus': {
            outline: 'none',
            borderColor: v2Colors.text.activeSecondary,
        },
    },
    columnLinkIcon: { marginLeft: '1rem', '& path': { fill: 'currentcolor' } },
    dropdownLink: {
        borderRadius: '8px',
        padding: '0.8rem',
        marginLeft: '-0.8rem',
        transition: 'background-color ease 500ms',
        '&:hover,:focus': {
            outline: 'none',
            backgroundColor: v2Colors.background.light,
        },
    },
    dropdownLinkContent: { display: 'flex', alignItems: 'center' },
    dropdownLinkIcon: { marginRight: '2rem', objectFit: 'contain' },
    cta: {
        padding: '3rem 3.5rem',
        backgroundColor: v2Colors.action.active,
        borderRadius: '5px',
        transition: 'transform ease 500ms, box-shadow ease 500ms',
        '&:hover,:focus': {
            outline: 'none',
            boxShadow: '0px 4px 8px rgba(102, 120, 140, 0.25)',
            transform: 'translateY(-1rem)',
        },
    },
    ctaImage: { height: '25rem', marginBottom: '2.4rem', width: '100%', objectFit: 'contain', borderRadius: '5px' },
    ctaTextSpacing: { marginBottom: '0.8rem' },
    ctaLinkText: { display: 'flex', alignItems: 'center' },
    ctaLinkIcon: { marginLeft: '1rem', '& path': { fill: 'currentcolor' } },
    leftMarginIcon: { marginLeft: '1rem' },
    smallLeftMarginIcon: { marginLeft: '0.5rem' },
    iconAlignment: { alignSelf: 'baseline' },
} as const;
