import React, { useEffect, useMemo, useState } from 'react';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { breakpoints } from '@web-for-marketing/react-ui';
import { AspectRatio } from '@models/images';
import { Interpolation, Theme } from '@emotion/react';
import { PageHelmetPreloadLink } from '@components/PageHelmetPreloadLink';

interface BaseProps {
    imagePath?: string;
    lazyLoad?: boolean;
    mobilePath?: string;
    pictureProps?: React.HTMLAttributes<HTMLPictureElement> & { css?: Interpolation<Theme> };
    alt: string | undefined;
    mobileAlt?: string;
    fullWidth?: boolean;
    mobileQuery?: `${number}px`;
    aspectRatio?: AspectRatio;
    mobileAspectRatio?: AspectRatio;
    preload?: boolean;
}

interface PictureStyleProps {
    aspectRatio?: AspectRatio;
    mobileAspectRatio?: AspectRatio;
    mobileQuery: string;
    imagePath?: string;
    mobilePath?: string;
}

export type PictureProps = BaseProps & Omit<React.HTMLAttributes<HTMLImageElement>, keyof BaseProps>;

const classes = {
    picture: {
        width: '100%',
        display: 'inline',
        height: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    image: {
        objectFit: 'cover',
    },
    aspectRatio: ({ aspectRatio, mobileAspectRatio, mobileQuery, imagePath, mobilePath }: PictureStyleProps) => ({
        aspectRatio: imagePath ? aspectRatio : mobilePath ? mobileAspectRatio : undefined,
        [`@media (max-width: ${mobileQuery})`]: {
            aspectRatio: mobilePath ? mobileAspectRatio : imagePath ? aspectRatio : undefined,
        },
    }),
} as const;

export function Picture({
    imagePath,
    mobilePath,
    alt,
    lazyLoad = false,
    mobileQuery = breakpoints.sm,
    fullWidth = true,
    className = '',
    pictureProps = {},
    aspectRatio,
    mobileAspectRatio,
    preload = false,
    mobileAlt,
    ...other
}: PictureProps): JSX.Element {
    const [altText, setAltText] = useState<string>(alt || '');
    const { prefixStringWithBaseUrl } = useStaticValues();
    const resolvedDesktopPath = useMemo(() => {
        return prefixStringWithBaseUrl(imagePath || mobilePath);
    }, [prefixStringWithBaseUrl, imagePath, mobilePath]);

    const resolvedMobilePath = useMemo(() => {
        return prefixStringWithBaseUrl(mobilePath || imagePath);
    }, [prefixStringWithBaseUrl, imagePath, mobilePath]);

    useEffect(() => {
        function updateAltText(): void {
            if (window.innerWidth < parseInt(mobileQuery)) {
                setAltText(mobileAlt || '');
            } else {
                setAltText(alt || '');
            }
        }

        window.addEventListener('resize', updateAltText);

        // Initial call to set alt text on component mount
        updateAltText();

        return () => {
            window.removeEventListener('resize', updateAltText);
        };
    }, [mobileQuery, alt, mobileAlt]);

    return (
        <>
            {resolvedDesktopPath && preload ? <PageHelmetPreloadLink as='image' href={resolvedDesktopPath} /> : null}
            {resolvedMobilePath && preload ? <PageHelmetPreloadLink as='image' href={resolvedMobilePath} /> : null}
            <picture
                {...pictureProps}
                css={[
                    classes.picture,
                    classes.aspectRatio({
                        aspectRatio,
                        mobileAspectRatio,
                        mobileQuery,
                        imagePath,
                        mobilePath,
                    }),
                ]}
                className={pictureProps?.className}
                data-testid='picture'
            >
                <source
                    srcSet={lazyLoad ? '' : resolvedMobilePath}
                    data-srcset={lazyLoad ? resolvedMobilePath : ''}
                    media={`(max-width: ${mobileQuery})`}
                />
                <img
                    src={lazyLoad ? '' : resolvedDesktopPath}
                    data-src={lazyLoad ? resolvedDesktopPath : ''}
                    alt={altText}
                    {...other}
                    css={[
                        classes.image,
                        fullWidth ? classes.fullWidth : undefined,
                        classes.aspectRatio({
                            aspectRatio,
                            mobileAspectRatio,
                            mobileQuery,
                            imagePath,
                            mobilePath,
                        }),
                    ]}
                    className={className}
                />
            </picture>
        </>
    );
}
